import { Link } from 'gatsby'
import React, { Component } from 'react'
import { convertPXToRem } from 'src/helpers/tool'
import logoWhite from 'src/images/header_logo_ms.png'
import logoColor from 'src/images/header_logo_fix_ms.png'
import Dropdown from 'src/components/Dropdown.js'
import Navbox from 'src/components/NavBox'
import styles from './header.module.scss'
import { HeaderConfig as configData } from 'src/helpers/constants'

const scrollHeight = 5 // rem

class Header extends Component {
  constructor() {
    super()
    this.state = {
      isFixed: false,
      path: null,
      show: false,
    }
    this.dropDown = React.createRef()
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
    let pathname = window.location.pathname.split('/')[1]
    this.setState({
      path: pathname,
    })
    this.handleScroll()
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll = () => {
    const { isFixed } = this.state
    let scrollTop = convertPXToRem(window.pageYOffset)
    if (scrollTop > scrollHeight && !isFixed) {
      this.setState({
        isFixed: true,
      })
    } else if (scrollTop <= scrollHeight && isFixed) {
      this.setState({
        isFixed: false,
      })
    }
  }

  toggleBox = () => {
    this.setState({
      show: !this.state.show,
    })
  }

  renderItem = (itemData, id) => {
    const { path, isFixed } = this.state

    if (itemData.pathType === 0 && !itemData.isH5) {
      return (
        <Link
          key={id}
          to={itemData.pathName}
          className={`${styles['nav-item']} ${
            path === itemData.pathItem ? styles['active-line'] : ''
          }`}
        >
          {itemData.name}
        </Link>
      )
    }
    if (itemData.pathType === 1) {
      return (
        <a
          key={id}
          href={itemData.pathName}
          className={styles['nav-item']}
          target="_blank"
        >
          {itemData.name}
        </a>
      )
    }
    if (typeof itemData.pathItem != 'string') {
      return (
        <Dropdown
          key={id}
          className={styles['dropdown-item']}
          dropDownItem={itemData.name}
          dropList={itemData.dataPath}
          isFixed={isFixed}
        />
      )
    }
  }

  render() {
    const { isFixed, path } = this.state

    return (
      <div className={styles['header']}>
        <div
          className={`${
            isFixed ? styles['navbar-fixed'] : ''
          }`}
        >
          <div className={styles['header-box']}>
            <Link to="/">
              <img src={isFixed ? logoColor : logoWhite} alt='logo'/>
            </Link>
            <div className={styles['nav-list']}>
              {configData.map((item, idx) => this.renderItem(item, idx))}
            </div>
            {/* <span className="login">登录</span> */}
          </div>
        </div>
        <div className={styles['mobile-header']}>
            <Link to="/">
              <img src={logoColor} alt='logoH5' />
            </Link>
            <a className={styles['mobile-btn']} onClick={this.toggleBox}>
              <span className={styles['icon-bar']}></span>
              <span className={styles['icon-bar']}></span>
              <span className={styles['icon-bar']}></span>
            </a>
          </div>
          {this.state.show && <Navbox toggleBox={this.toggleBox} path={path}/>}
      </div>
    )
  }
}

export default Header
