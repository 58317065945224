import React from 'react'

import './icon.module.scss'

const Icon = ({ icon, className, onClick }) => {
  return (
    <span
      className={`icon i-${icon} ${className || ''}`}
      onClick={onClick}
    ></span>
  )
}

export default Icon
