function setBaseUrl() {
  const HOST = window.location.host
  let baseURL = ""
  const envMap = {
    NDEV: 'www.ndev.imdada.cn',
    QA: 'www.qa.imdada.cn',
    ONLINE: 'www.imdada.cn',
  }

  switch (HOST) {
    case envMap.NDEV:
      baseURL = "https://supplier-api.ndev.imdada.cn";
      break;
    case envMap.QA:
      baseURL = "https://supplier-api.qa.imdada.cn";
      break;
    case envMap.ONLINE:
      baseURL = "https://supplier-api.imdada.cn";
      break;
    default:
      baseURL = "http://supplier-api.ndev.imdada.cn";
      break;
  }
  return baseURL
}

const request = (url, config) => {
  config = config || {};
  let {
    headers: headers_config,
    body,
    method = 'GET'
  } = config;

  // 处理headers
  let headers = {
    'Content-Type': 'application/json'
  };
  if (headers_config) {
    headers = Object.assign(headers, headers_config);
  }

  if (body !== null && typeof body === "object") {
    const ContentType = headers['Content-Type']
    if (ContentType === "application/json") {
      body = JSON.stringify(body)
    }
  }

  // 发送请求
  url = setBaseUrl() + url
  config = {
    method,
    headers,
    body,
    cache: 'no-cache',
  };

  return fetch(url, config).then(response => {
    const { status } = response
    if (status >= 200 && status < 300) {
      return response.json();
    }
    return Promise.reject({
      status: response.status,
      statusText: response.statusText
    });
  }).catch(reason => {
    return Promise.reject(reason);
  });
}

export default request