import React, { useState, useEffect } from 'react'
import SectionTitle from 'src/components/SectionTitle'
import Swiper from 'swiper'
import 'swiper/swiper.scss'
import styles from './zhipei_partner.module.scss'
import qixian from '../images/partner/qixian.png'
import qixian_active from '../images/partner/qixian-active.png'
import qixian_left from '../images/partner/qixian-left.png'
import liansheng from '../images/partner/liansheng.png'
import liansheng_active from '../images/partner/liansheng-active.png'
import liansheng_left from '../images/partner/liansheng-left.png'
import jingdong from '../images/partner/jingdong.png'
import jingdong_active from '../images/partner/jingdong-active.png'
import jingdong_left from '../images/partner/jingdong-left.png'
import tianxie from '../images/partner/tianxie.png'
import tianxie_active from '../images/partner/tianxie-active.png'
import tianxie_left from '../images/partner/tianxie-left.png'

const partners = [
  {
    name: 'qixian',
    text: '通过达达智配系统的智能算法进行障碍物判断，毫秒级筛选出最优配送路线，帮助七鲜的配送骑士更高效、更快速的完成订单配送。',
    img: qixian,
    img_active: qixian_active,
    img_left: qixian_left,
  },
  {
    name: 'liansheng',
    text: '上线达达智配SaaS系统后，成功将数千店员转为自配送骑士，并基于智能派单、人工调度台、骑士抢单等能力，实现不同门店灵活派单，有效协调骑士资源。',
    img: liansheng,
    img_active: liansheng_active,
    img_left: liansheng_left,
  },
  {
    name: 'jingdong',
    text: '通过达达智配SaaS系统，九江联盛无缝衔对接外卖平台、小程序等全渠道订单，实现了全渠道自配送。基于门店看板、运营日报等数据分析功能，及时调整运力策略，有效保障用户配送体验。',
    img: jingdong,
    img_active: jingdong_active,
    img_left: jingdong_left,
  },
  {
    name: 'tianxie',
    text: '为知名连锁餐饮门店提供配送服务，在接入达达智配后，客户满意度整体大幅提升，人力成本有效降低。',
    img: tianxie,
    img_active: tianxie_active,
    img_left: tianxie_left,
  }
]

export default () => {
  const [activeIndex, setActiveIndex] = useState(0)
  const handleClick = index => setActiveIndex(index)
  useEffect(() => {
    // 初始化解决方案轮播
    const { clientWidth } = document.documentElement
    clientWidth < 750 && new Swiper('.swiper-container', {
      autoplay: true,
      loop: true,
      pagination: {
        el: '.swiper-pagination',
        bulletActiveClass: 'zhipei_partner-module--bullet-active',
      },
      on: {
        slideChange: function () {
          setActiveIndex(this.realIndex)
        },
      },
    })
  }, [])

  return (
    <div className={styles['partner']}>
      <div className={styles['partner-title']}>
        <SectionTitle title='合作案例' engTitle='CASE' />
      </div>
      <div className={styles['wrapper']}>
        <div className={styles['navbar']}>
          {
            partners.map((item, index) => (
              <div className={`${styles['navbar-item']} ${activeIndex === index ? styles['active'] : ''}`} onClick={() => handleClick(index)} key={index} >
                {activeIndex === index ? <img className={styles['item-img']} src={item.img_active} alt='zhipei' /> : <img className={styles['item-img']} src={item.img} alt='zhipei' key={index} />}
              </div>
            ))
          }
        </div>
        <div className={styles['content']}>
          <img className={styles['left-img']} src={partners[activeIndex].img_left} />
          <div className={styles['text']}>{partners[activeIndex].text}</div>
        </div>
      </div>
      <div className={styles['wrapper-sm']}>
        <div className="swiper-container" style={{ width: '17.5rem' }}>
          <div className="swiper-wrapper">
            {
              partners.map((item, index) => (
                <div className="swiper-slide" key={index}>
                  <div className={styles['img-sm-wrapper']}>
                    <img className={styles['img-sm']} src={item.img_active} />
                  </div>
                  <img className={styles['left-img-sm']} src={partners[activeIndex].img_left} />
                  <div className={styles['text-sm']}>{partners[activeIndex].text}</div>
                </div>
              ))
            }
          </div>
        </div>
        <div className={styles['bullet-wrapper']}>
          {
            partners.map((item, index) => (
              <div className={`${styles['bullet']} ${activeIndex === index ? styles['bullet-active'] : ''}`} key={index}></div>
            ))
          }
        </div>
      </div>
    </div >

  )
}

