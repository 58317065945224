const HeaderConfig = [
  {
    pathType: 0, // 内部路由跳转
    pathItem: '',
    pathName: '/',
    name: '首页',
  },
  {
    pathType: 0, // 内部路由跳转
    pathItem: 'merchant',
    pathName: '/merchant/',
    name: '商家服务',
  },
  {
    pathType: 1, // 外部路由跳转
    pathItem: 'https://kuai.imdada.cn/',
    pathName: 'https://kuai.imdada.cn/',
    name: '在线下单',
  },
  {
    pathType: 0, // 内部路由跳转
    pathItem: 'enterprise',
    pathName: '/enterprise/',
    name: '企业版',
  },
  {
    pathType: 0, // 内部路由跳转
    pathItem: 'transporter',
    pathName: '/transporter/',
    name: '成为骑士',
  },
  {
    dataPath: [
      {
        pathType: 0,
        pathName: '/zhipei/',
        submenu: '智配系统',
        mobile: false,
      },
      {
        pathName: 'https://about.imdada.cn/unmanned/',
        submenu: '无人配送',
        mobile: true,
      },
    ],
    pathItem: ['zhipei'],
    name: '配送系统',
  },
  {
    dataPath: [
      {
        pathName: 'https://newopen.imdada.cn/',
        submenu: '开放平台',
        mobile: false,
      },
      {
        pathName: 'https://about.imdada.cn/freight/',
        submenu: '货运服务商合作',
        mobile: true,
      },
      {
        pathName: 'https://about.imdada.cn/cooperation/',
        submenu: '配送代理商合作',
        mobile: true,
      },
    ],
    pathItem: ['job', 'job-society', 'job-campus'],
    name: '招商合作',
  },
  {
    dataPath: [
      {
        pathName: 'https://about.imdada.cn/job/',
        submenu: '社会招聘',
        mobile: false,
      },
      {
        pathName: 'https://app.mokahr.com/campus_apply/imdada/7841#/',
        submenu: '校园招聘',
        mobile: true,
      },
    ],
    pathItem: ['job', 'job-society', 'job-campus'],
    name: '加入我们',
  },
  {
    pathType: 0, // 内部路由跳转
    isH5: true,
    pathItem: 'license',
    pathName: '/license/',
    name: '营业执照',
  },
  {
    pathType: 1, // 外部路由跳转
    pathItem: 'https://about.imdada.cn/',
    pathName: 'https://about.imdada.cn/',
    name: '关于我们',
  },
]

export { HeaderConfig }
