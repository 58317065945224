import React from 'react'
import { Link } from 'gatsby'
import { getDate } from 'src/helpers/tool'
import fraudNormalImage from 'src/images/footer/fraud_normal.png'
import fraudClickImage from 'src/images/footer/fraud_click.png'
import fraudHoverImage from 'src/images/footer/fraud_hover.png'
import beian from 'src/images/footer/beian.png'
import styles from './footer.module.scss'

const Footer = () => (
  <div className={styles['footer']}>
    <div className={styles['fraud']}>
      <img className={styles['fraud-img']} src={fraudNormalImage} alt='fraud' />
      <img className={styles['fraud-img-click']} src={fraudClickImage} alt='fraudClick' />
      <img className={styles['fraud-img-hover']} src={fraudHoverImage} alt='fraudHover' />
      <div className={styles['fraud-box']}>亲爱的市民朋友，上海警方反诈劝阻电话“962110”系专门针对避免您财产被骗受损而设，请您一旦收到来电，立即接听。</div>
    </div>
    <div>&copy; {getDate()} 达达集团</div>
    <div>ICP证：合B2-20230071 上海趣盛网络科技有限公司</div>
    <a href='http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011502003212' target='_blank'>
      <img className={styles['beian-img']} src={beian} alt='beian' />沪公网安备 31011502003212号
    </a>
    <Link to='/license/' className={styles['license-entry']}>营业执照</Link>
  </div>
)

export default Footer
