import React from 'react';
import Icon from 'src/components/Icon'
import styles from './dropdown.module.scss'

class Dropdown extends React.Component {
  constructor() {
    super();
    this.state = {
      displayMenu: false,
    };
    this.showDropdownMenu = this.showDropdownMenu.bind(this);
    this.hideDropdownMenu = this.hideDropdownMenu.bind(this);
  }

  showDropdownMenu(event) {
    event.preventDefault();
    this.setState({ displayMenu: true }, () => {
      document.addEventListener('click', this.hideDropdownMenu)
    })
  }

  hideDropdownMenu() {
    this.setState({ displayMenu: false }, () => {
      document.removeEventListener('click', this.hideDropdownMenu)
    })
  }

  render() {
    const { dropDownItem, dropList, isFixed } = this.props
    const { displayMenu } = this.state

    return (
      <div className={`${styles['dropdown']} ${isFixed ? styles['nav-fix'] : ''}`}>
        <div className={`${styles['dropdown-btn']} 
             ${displayMenu ? styles['open'] : ''}`} 
             onClick={this.showDropdownMenu}>
          <span>{dropDownItem}</span>
          <Icon className={`${styles['icon']}`} icon={displayMenu ? 'arrow_up' : 'arrow_down'}></Icon>
        </div>
        <div className={styles['dropdown-box']}>
          {
            this.state.displayMenu ? (
                dropList.map((item, idx) => (
                  <div key={idx} className={`${styles['dropdown-list']} ${displayMenu ? styles['open'] : ''}`}>
                      <a href={item.pathName} target='_blank'>{item.submenu}</a>
                  </div>
                ))
            ) : null
          }
        </div>
      </div>

    );
  }
}
export default Dropdown;

