/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import { withErrorBoundary } from '@dada/react-error-boundary';
import PropTypes from 'prop-types'
import style from './layout.module.scss'
import Header from 'src/components/Header'
import Footer from 'src/components/Footer'

const Layout = ({ children, mobile }) => {
  return (
    <div className={style['layout-box']}>
      <Header />
      {mobile && <div className={style.mobile}>{mobile}</div>}
      <div className={`${style['layout-content']} ${mobile && style['pc']}`}>
        <main>{children}</main>
      </div>
      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default withErrorBoundary(Layout)
