export const getDate = () => {
  return new Date().getFullYear()
}

export const convertPXToRem = px => {
  const fontSize = getComputedStyle(document.documentElement).fontSize
  return px / parseFloat(fontSize)
}

export const isMobile = userAgent => {
  let deviceRgx = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i
  return deviceRgx.test(userAgent)
}

export const isIOS = userAgent => {
  let deviceIosRgx =/(iPhone|iPod|iPad)/i
  return deviceIosRgx.test(userAgent)
}

