import React from 'react'
import SectionTitle from 'src/components/SectionTitle.js'
import './ZhipeiAbout.scss'

const data = [
  '达达智配是达达秒送基于自身智慧物流系统和苍穹大数据平台，历经数年研发打磨的开放物流服务系统。达达智配以“让调度更智能，配送更高效”为目标，专门为配送服务商、拥有自配送运力的品牌商家提供多场景、一体化SaaS系统解决方案。',
  '达达智配SaaS系统涵盖全渠道订单接入、订单派送管理、运力调度三大能力模块。终端产品层面，达达智配SaaS系统为合作伙伴提供调度操作后台、调度App、骑士App，其功能完整覆盖订单派送管理与配送人员管理。进而提升配送履约效率与配送人员管理效率，节约配送成本。'
]

export default () => {
  return (
    <div className="zhipei-about">
      <SectionTitle title="关于达达智配" engTitle="ABOUT" />
      {
        data.map((item, index) => (
          <div className="zhipei-about-text" key={index}>{item}</div>
        ))
      }
    </div>
  )
}