import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import SectionTitle from 'src/components/SectionTitle.js'
import styles from './main_functions.module.scss'

export default ({ }) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const data = useStaticQuery(graphql`
    query {
      order: file(relativePath: { eq: "main_functions/order_ms.png" }) {
        childImageSharp {
          fluid(maxWidth: 120, maxHeight: 120) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      desk: file(relativePath: { eq: "main_functions/desk_ms.png" }) {
        childImageSharp {
          fluid(maxWidth: 120, maxHeight: 120) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      store: file(relativePath: { eq: "main_functions/store_ms.png" }) {
        childImageSharp {
          fluid(maxWidth: 120, maxHeight: 120) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      knight: file(relativePath: { eq: "main_functions/knight_ms.png" }) {
        childImageSharp {
          fluid(maxWidth: 120, maxHeight: 120) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      depot: file(relativePath: { eq: "main_functions/depot_ms.png" }) {
        childImageSharp {
          fluid(maxWidth: 120, maxHeight: 120) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      rights: file(relativePath: { eq: "main_functions/rights_ms.png" }) {
        childImageSharp {
          fluid(maxWidth: 120, maxHeight: 120) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      operation: file(relativePath: { eq: "main_functions/operation_ms.png" }) {
        childImageSharp {
          fluid(maxWidth: 120, maxHeight: 120) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      store_desk: file(relativePath: { eq: "main_functions/store_desk_ms.png" }) {
        childImageSharp {
          fluid(maxWidth: 120, maxHeight: 120) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      report: file(relativePath: { eq: "main_functions/report_ms.png" }) {
        childImageSharp {
          fluid(maxWidth: 120, maxHeight: 120) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      store_new: file(relativePath: { eq: "main_functions/store_new_ms.png" }) {
        childImageSharp {
          fluid(maxWidth: 120, maxHeight: 120) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      online: file(relativePath: { eq: "main_functions/online_ms.png" }) {
        childImageSharp {
          fluid(maxWidth: 120, maxHeight: 120) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      rules: file(relativePath: { eq: "main_functions/rules_ms.png" }) {
        childImageSharp {
          fluid(maxWidth: 120, maxHeight: 120) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      valid: file(relativePath: { eq: "main_functions/valid_ms.png" }) {
        childImageSharp {
          fluid(maxWidth: 120, maxHeight: 120) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const functions = [
    {
      name: '全面智能化管理',
      desc: '管理全面精细化：全门店实时动态强管控',
      items: [{
        icon: data.order.childImageSharp.fluid,
        name: '订单管理'
      }, {
        icon: data.desk.childImageSharp.fluid,
        name: '人工调度台'
      }, {
        icon: data.store.childImageSharp.fluid,
        name: '驻店管理'
      }, {
        icon: data.knight.childImageSharp.fluid,
        name: '骑士管理'
      }, {
        icon: data.depot.childImageSharp.fluid,
        name: '站务管理'
      }, {
        icon: data.rights.childImageSharp.fluid,
        name: '权限管理'
      }]
    },
    {
      name: '运营数据精准化',
      desc: '拒绝拍脑袋做生意：全数据透析面面俱到',
      items: [{
        icon: data.operation.childImageSharp.fluid,
        name: '运营日报'
      }, {
        icon: data.store_desk.childImageSharp.fluid,
        name: '门店看板'
      }, {
        icon: data.report.childImageSharp.fluid,
        name: '运营月报'
      }]
    },
    {
      name: '配送联盟定运力',
      desc: '多渠道运力保兜底：全运力聚合赋能门店',
      items: [{
        icon: data.store_new.childImageSharp.fluid,
        name: '门店新建'
      }, {
        icon: data.online.childImageSharp.fluid,
        name: '运力配置'
      }, {
        icon: data.rules.childImageSharp.fluid,
        name: '下发规则'
      }, {
        icon: data.valid.childImageSharp.fluid,
        name: '门店生效'
      }]
    }
  ]
  const clickHandle = (index) => {
    setActiveIndex(index)
  }
  return (
    <div className={styles['main-functions']}>
      <SectionTitle title="核心功能" engTitle="FUNCTIONS" />
      <div className={styles['content']}>
        <span className={styles['title']}>产品功能专业全面，专注细节提升配送效率</span>
        <div className={styles['tabs']}>
          <div className={styles['tabs-list']}>
            <div className={`${styles['tabs-list-wrapper']} ${styles[`tabs-list-wrapper${activeIndex}`]}`}>
              {functions.map((item, index) => (
                <div className={styles['tab-name']} key={index}>
                  <span key={index} onClick={() => clickHandle(index)} className={`${activeIndex === index ? styles['active'] : ''}`}>{item.name}</span>
                  {index === activeIndex && <span className={styles['line']}></span>}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className={styles['desc']}>{functions[activeIndex].desc}</div>
        <div className={styles['functions']}>
          {functions[activeIndex].items.map(item => (
            <div className={`${styles['function-item']} ${activeIndex === 1 ? styles['item1'] : ''} ${activeIndex === 2 ? styles['item2'] : ''} `} key={item.name}>
              <div className={styles['function-img']}>
                <Img fluid={item.icon} alt='function' />
              </div>
              <span className={styles['name']}>{item.name}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
