import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import SectionTitle from 'src/components/SectionTitle.js'
import Icon from 'src/components/Icon'
import styles from './contact.module.scss'

export default () => {
  const [activeIndex, setActiveIndex] = useState(-1)
  const data = useStaticQuery(graphql`
    query {
      chatCode: file(relativePath: { eq: "contact/chatCode.png" }) {
        childImageSharp {
          fluid(maxWidth: 596, maxHeight: 596) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const contactWays = [
    {
      icon: 'icon-phone',
      desc: '13402035718'
    },
    {
      icon: 'iconfont icon-comment',
      desc: '扫码咨询专业顾问'
    },
    {
      icon: 'icon-envelope',
      desc: 'yujiahao7@jd.com'
    },
  ]
  return (
    <div className={styles['contact']}>
      <SectionTitle title="联系我们" engTitle="CONTACT" colorStyle={true} />
      <div className={styles['info']}>
        <p className={styles['title']}>商务合作</p>
        <div className={styles['contact-box']}>
          <div className={styles['row']}>
          {activeIndex === 1 && <div className={styles['chat-code']}>
            <Img fluid={data.chatCode.childImageSharp.fluid} alt='contact' />
          </div>}
            {
              contactWays.map((item, index) => (
                <div className={`${styles['item']} ${activeIndex === index ? styles['active'] : ''}`} onMouseMove={() => setActiveIndex(index)} onMouseLeave={() => setActiveIndex(-1)} key={index}>
                  <Icon className={`iconfont ${item.icon} ${styles['iconfont']} ${activeIndex === index ? styles['active'] : ''}`}></Icon>
                  <a className={`${styles['desc']} ${activeIndex === index ? styles['active'] : ''}`}>{item.desc}</a>
                </div>
              ))
            }
          </div>
        </div>
      </div>
    </div>
  )
}
