import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import SectionTitle from 'src/components/SectionTitle.js'
import styles from './main_advantages.module.scss'


export default () => {
  const [activeIndex, setActiveIndex] = useState(0)
  const data = useStaticQuery(graphql`
    query {
      system: file(relativePath: { eq: "advantages/system.png" }) {
        childImageSharp {
          fluid(maxWidth: 120, maxHeight: 120) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      system_active: file(relativePath: { eq: "advantages/system_active_ms.png" }) {
        childImageSharp {
          fluid(maxWidth: 120, maxHeight: 120) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      system_img: file(relativePath: { eq: "advantages/system_img_1_ms.png" }) {
        childImageSharp {
          fluid(maxWidth: 1094, maxHeight: 872) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      product: file(relativePath: { eq: "advantages/product.png" }) {
        childImageSharp {
          fluid(maxWidth: 120, maxHeight: 120) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      product_active: file(relativePath: { eq: "advantages/product_active_ms.png" }) {
        childImageSharp {
          fluid(maxWidth: 120, maxHeight: 120) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      product_img: file(relativePath: { eq: "advantages/product_img_1_ms.png" }) {
        childImageSharp {
          fluid(maxWidth: 1094, maxHeight: 872) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      service: file(relativePath: { eq: "advantages/service.png" }) {
        childImageSharp {
          fluid(maxWidth: 120, maxHeight: 120) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      service_active: file(relativePath: { eq: "advantages/service_active_ms.png" }) {
        childImageSharp {
          fluid(maxWidth: 120, maxHeight: 120) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      service_img: file(relativePath: { eq: "advantages/service_img_1_ms.png" }) {
        childImageSharp {
          fluid(maxWidth: 1094, maxHeight: 872) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const advantages = [
    {
      tabName: '产品优势',
      img: data.system_img.childImageSharp.fluid,
      tabIcon: data.system.childImageSharp.fluid,
      tabIconActive: data.system_active.childImageSharp.fluid,
      title: '三大终端产品支持，全方位解决自配难题',
      desc: '达达智配SaaS系统主要由商家总管理后台、商家调度App、骑士App三大终端版块组成，能够无缝对接POS、小程序、APP等商家渠道订单，也能聚合美团、饿了么等平台订单，让全渠道订单可以接入并实现商家自配送'
    },
    {
      tabName: '算法优势',
      img: data.product_img.childImageSharp.fluid,
      tabIcon: data.product.childImageSharp.fluid,
      tabIconActive: data.product_active.childImageSharp.fluid,
      title: '行业经验丰富，智能算法强大',
      desc: '依托达达秒送8年来沉淀的骑士路线、配送履约数据，多行业配送经验，已经具备领先的智能派单算法能力，能够帮助企业实现全渠道待配送订单智能分配给最合适的骑士配送'
    },
    {
      tabName: '服务优势',
      img: data.service_img.childImageSharp.fluid,
      tabIcon: data.service.childImageSharp.fluid,
      tabIconActive: data.service_active.childImageSharp.fluid,
      title: '及时响应商家问题，提供专业运营支持',
      desc: '针对合作商家设立问题反馈机制，快速响应及排查，同时也为合作商家配置运营经理，提供运营咨询服务，定期帮助商家分析配送数据，制定个性化配送解决方案'
    }
  ]
  const clickHandle = (index) => {
    setActiveIndex(index)
  }
  return (
    <div className={styles['main-advantages']}>
      <SectionTitle title="核心优势" engTitle="ANDVANTAGE" />
      <div className={styles['content']}>
        <div className={styles['advantage-img']}>
          <Img fluid={advantages[activeIndex].img} alt='advantages' />
        </div>
        <div className={styles['advantage-content']}>
          <div className={styles['tabs']}>
            {advantages.map((item, index) => (
              <div onClick={() => clickHandle(index)} className={styles['tab']} key={index}>
                <div className={styles['tab-icon']}>
                  <Img fluid={activeIndex === index ? item.tabIconActive : item.tabIcon} alt='advantages' />
                </div>
                <span className={`${styles['tab-name']} ${activeIndex === index ? styles['active'] : ''}`}>{item.tabName}</span>
              </div>
            ))}
          </div>
          <div>
            <span className={styles['title']}>{advantages[activeIndex].title}</span>
            <span className={styles['desc']}>{advantages[activeIndex].desc}</span>
          </div>
        </div>
      </div>
    </div>
  )
}
