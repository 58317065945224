import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import Icon from 'src/components/Icon'
import styles from './contact_fix.module.scss'
export default (props) => {
  const { toggleModal } = props
  const [showPhone, setShowPhone] = useState(false)
  const [showCode, setShowCode] = useState(false)
  const data = useStaticQuery(graphql`
    query {
      contactCode: file(relativePath: { eq: "contact/chatCode.png" }) {
        childImageSharp {
          fluid(maxWidth: 596, maxHeight: 596) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <div className={styles['contact']}>
      <div className={styles['phone']}>
        <div className={`${styles['phone-text']} ${showPhone ? styles['active'] : ''}`} onMouseMove={() => setShowPhone(true)} onMouseLeave={() => setShowPhone(false)} onClick={toggleModal}>
          <Icon className={`iconfont icon-news-o ${styles['iconfont']} ${showPhone ? styles['active'] : ''}`} />
          <span>申请合作</span>
        </div>
      </div>
      <div className={styles['code']}>
        {showCode && <div className={styles['code-img']}>
          <Img fluid={data.contactCode.childImageSharp.fluid} />
        </div>}
        <div className={`${styles['code-text']} ${showCode ? styles['active'] : ''}`} onMouseMove={() => setShowCode(true)} onMouseLeave={() => setShowCode(false)}>
          <Icon className={`iconfont icon-scanning ${styles['iconfont']} ${showCode ? styles['active'] : ''}`} />
          <span>扫码入群</span>
        </div>
      </div>
    </div>
  )
}
