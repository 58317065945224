import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import SectionTitle from 'src/components/SectionTitle.js'
import styles from './programme.module.scss'


export default () => {
  const [activeIndex, setActiveIndex] = useState(0)
  const data = useStaticQuery(graphql`
    query {
      ai: file(relativePath: { eq: "programmes/ai.png" }) {
        childImageSharp {
          fluid(maxWidth: 120, maxHeight: 120) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ai_active: file(relativePath: { eq: "programmes/ai_active_1_ms.png" }) {
        childImageSharp {
          fluid(maxWidth: 120, maxHeight: 120) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ai_img: file(relativePath: { eq: "programmes/ai_img_1_ms.png" }) {
        childImageSharp {
          fluid(maxWidth: 1094, maxHeight: 872) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      crowd: file(relativePath: { eq: "programmes/crowd.png" }) {
        childImageSharp {
          fluid(maxWidth: 120, maxHeight: 120) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      crowd_active: file(relativePath: { eq: "programmes/crowd_active_1_ms.png" }) {
        childImageSharp {
          fluid(maxWidth: 120, maxHeight: 120) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      crowd_img: file(relativePath: { eq: "programmes/crowd_img_1_ms.png" }) {
        childImageSharp {
          fluid(maxWidth: 1094, maxHeight: 872) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      transport: file(relativePath: { eq: "programmes/transport.png" }) {
        childImageSharp {
          fluid(maxWidth: 120, maxHeight: 120) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      transport_active: file(relativePath: { eq: "programmes/transport_active_1_ms.png" }) {
        childImageSharp {
          fluid(maxWidth: 120, maxHeight: 120) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      transport_img: file(relativePath: { eq: "programmes/transport_img_1_ms.png" }) {
        childImageSharp {
          fluid(maxWidth: 1094, maxHeight: 872) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      human: file(relativePath: { eq: "programmes/human.png" }) {
        childImageSharp {
          fluid(maxWidth: 120, maxHeight: 120) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      human_active: file(relativePath: { eq: "programmes/human_active_1_ms.png" }) {
        childImageSharp {
          fluid(maxWidth: 120, maxHeight: 120) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      human_img: file(relativePath: { eq: "programmes/human_img_1_ms.png" }) {
        childImageSharp {
          fluid(maxWidth: 1094, maxHeight: 872) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const programmes = [
		{
			tabName: 'AI智能',
			img: data.ai_img.childImageSharp.fluid,
			tabIcon: data.ai.childImageSharp.fluid,
			tabIconActive: data.ai_active.childImageSharp.fluid,
			title: 'AI智能赋能自配送团队',
			desc: '目前市面上许多有自配送团队的品牌或服务商仍采用人工指派等传统调度模式，无后端数据看板实时监控，导致骑士工作效率低，管理效率低，达达智配通过智能派单、运营数据、骑士追踪等功能，助力商家提升骑士人效'
		},
		{
			tabName: '众包削峰',
			img: data.crowd_img.childImageSharp.fluid,
			tabIcon: data.crowd.childImageSharp.fluid,
			tabIconActive: data.crowd_active.childImageSharp.fluid,
			title: '众包削峰保服务',
			desc: '针对有自配送团队的商家在早晚高峰出现自有运力不足的情况，达达智配系统基于达达秒送数十万众包池，提供众包运力，帮助自配送团队削峰'
		},
		{
			tabName: '运力赋能',
			img: data.transport_img.childImageSharp.fluid,
			tabIcon: data.transport.childImageSharp.fluid,
			tabIconActive: data.transport_active.childImageSharp.fluid,
			title: '运力赋能终端门店',
			desc: '许多餐饮，超市，医药，生鲜蛋糕商家无自己的配送团队，无法满足消费者即时性的消费需求，达达智配基于达达秒送数十万的众包池，为商家提供即时送达服务，保障用户体验'
		},
		{
			tabName: '人力成本',
			img: data.human_img.childImageSharp.fluid,
			tabIcon: data.human.childImageSharp.fluid,
			tabIconActive: data.human_active.childImageSharp.fluid,
			title: '人力成本优化',
			desc: '骑手用工成本高、运营风险高、发薪复杂等问题一直困扰着商家，针对这些现状，达达智配系统提供灵活发薪、人力成本优化等解决方案，帮助商家有效降低人力成本和运营风险'
		}
	]
  const clickHandle = (index) => {
    setActiveIndex(index)
  }
  return (
    <div className={styles['programmes']}>
      <SectionTitle title="解决方案" engTitle="PROGRAMME" />
      <div className={styles['programmes-intro']}>基于强大的算法能力改变传统调度模式，以数字化的方式提升管理与配送效率</div>
      <div className={styles['content']}>
        <div className={styles['programmes-content']}>
          <div className={styles['tabs']}>
            {programmes.map((item, index) => (
              <div onClick={() => clickHandle(index)} className={styles['tab']} key={index}>
                <div className={styles['tab-icon']}>
                  <Img fluid={activeIndex === index ? item.tabIconActive : item.tabIcon} alt='programmes'/>
                </div>
                <span className={`${styles['tab-name']} ${activeIndex === index ? styles['active']: ''}`}>{item.tabName}</span>
              </div>
            ))}
          </div>
          <div>
            <span className={styles['title']}>{programmes[activeIndex].title}</span>
            <span className={styles['desc']}>{programmes[activeIndex].desc}</span>
          </div>
        </div>
        <div className={styles['programmes-img']}>
          <Img fluid={programmes[activeIndex].img} alt='programme'  />
        </div>
      </div>
    </div>
  )
}
