import React, { useState } from 'react'
import { Link } from 'gatsby'
import './nav_box.scss'
import Icon from './Icon'
import logoColor from 'src/images/header_logo_fix_ms.png'
import { HeaderConfig } from 'src/helpers/constants'

const NavItem = (item, idx) => {
  if (item.dataPath) {
    return item.dataPath.map((itm, itmIdx) => {
      if (!itm.mobile) return null

      return <a href={itm.pathName} key={itmIdx}>{itm.submenu}</a>
    })
  }
  if (item.pathType === 0) {
    return <Link to={item.pathName} key={idx}>{item.name}</Link>
  }

  if (item.pathType === 1) {
    return <a href={item.pathName} key={idx}>{item.name}</a>
  }
}

const Navbox = ({ toggleBox, path}) => {
  const [currentIndex, setCurrentIndex] = useState(-1)

  const handleClick = (index) => {
    currentIndex === -1 ? setCurrentIndex(index) : (currentIndex === index ? setCurrentIndex(-1) : setCurrentIndex(index) )
  }


  return (
    <header className="header-box">
      <div className="nav-modle-head">
        <Link to="/">
          <img className="logo-white" src={logoColor} />
        </Link>
        <div onClick={toggleBox} className='close-btn'></div>
      </div>
      <div className="nav-list">
        {
          HeaderConfig.map((item, index) => (
            <div className="nav-item-wrapper" key={index}>
              {!item.dataPath ?
                <a className="nav-item" href={`${item.pathName}`}>
                  <div onClick={toggleBox} className={ path === item.pathItem ? 'nav-name-active' : ''} >
                    {item.name}
                  </div>
                </a>
                :
                <div className="nav-item" onClick={() => handleClick(index)}>
                  <span>{item.name}</span>
                  <Icon icon="angle-right" className={`angle-right ${currentIndex === index ? 'angle-right-active' : ''}`}/>
                </div>
              }
              <div className='nav-lower-wrapper'>
                {item.dataPath && (item.dataPath.length) ?
                  <div className='nav-lower-list' style={{ height: currentIndex === index ? `${item.dataPath.length * 3.13}rem` : '0' }}>
                    {item.dataPath.map((ite, ind) => (
                      <a className="nav-lower-item" href={`${ite.pathName}`} key={ind} >
                        <div onClick={toggleBox} className={ path && ite.pathName && (ite.pathName.indexOf(path) > -1) ? 'nav-name-active' : ''}>
                          {ite.submenu}
                        </div>
                      </a>
                    ))}
                  </div> : null
                }
              </div>
            </div>
          ))

        }
      </div>
    </header >
  )
}

export default Navbox
