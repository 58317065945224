import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import styles from './mobile.module.scss'

const IndexMobile = () => {
  const data = useStaticQuery(graphql`
    query {
      kuai: file(relativePath: { eq: "kuai_2_ms.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const handleClick = () => {
    window.open('http://kuai.imdada.cn/toc/corp/index_v2/billing')
  }

  return (
    <>
      <Img fluid={data.kuai.childImageSharp.fluid} alt='indexImg' />
      <div className={styles['order-btn']} onClick={handleClick}></div>
    </>
  )
}

export default IndexMobile
