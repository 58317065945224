import React, { useState, useEffect } from 'react'
import Layout from "src/components/Layout"
import SEO from 'src/components/Seo'
import MainFunctions from 'src/components/MainFuncitons'
import MainAdvantages from 'src/components/MainAdvantages'
import Programme from 'src/components/Programme'
import Contact from 'src/components/Contact'
import ContactFix from 'src/components/ContactFix'
import IndexMobile from 'src/components/IndexMobile'
import HeaderImg from 'src/components/HeaderImg'
import ZhipeiPartner from 'src/components/ZhipeiPartner'
import ZhipeiAbout from 'src/components/ZhipeiAbout'
import ContactModal from 'src/components/Contact_modal'
import bg_sm from 'src/images/partner/bg-sm.jpg'

const ZhipeiPage = () => {
	const [showModal, setShowModal] = useState(false)
	const toggleModal = () => setShowModal(!showModal)
	return (
		<Layout>
			<SEO title="达达智配" />
			<div>
				<ContactFix toggleModal={toggleModal} />
				<div className="show-bg">
					<HeaderImg img="zhipei" />
				</div>
				<img className="show-sm" src={bg_sm} style={{ 'width': '100%' }} />
				<ZhipeiAbout />
				<MainFunctions />
				<MainAdvantages />
				<Programme />
				<ZhipeiPartner />
				<Contact />
				{
					showModal && <ContactModal title="申请合作" toggleModal={toggleModal} />
				}
			</div>
		</Layout>
	)
}
export default ZhipeiPage