import React, { useState } from 'react'
import { applyCooperation } from '../fetch/api'
import Icon from 'src/components/Icon'
import './Contact_modal.scss'

const formObj = {
  merchantName: '',
  merchantPhone: '',
  merchantBrand: '',
}
export default (props) => {
  const { toggleModal, title = '联系我们' } = props
  const [form, setForm] = useState(formObj)

  const handleInputChange = event => {
    const {name, value} = event.target;
    setForm({ ...form, [name]: value })
  }

  const onSubmit = () => {
    let message = ''
    const { merchantName, merchantPhone, merchantBrand } = form
    const reg =/^1\d{10}$/;
    if (!merchantName.trim()) {
      message = '请输入姓名'
    }else if(!merchantPhone.trim()) {
      message = '请输入手机号'
    }else if(!reg.test(merchantPhone)) {
      message = '手机号格式不正确'
    }else if(!merchantBrand.trim()) {
      message = '请输入品牌名称'
    }
    if(message) return alert(message)
    
    applyCooperation(form).then(res => {
      if(res.status === 'ok') {
        alert('提交成功')
        toggleModal()
        setForm(formObj)
      }else {
        alert(res.errorMsg)
      }
    })
  }

  return (
  <div className="merchant-modal flex-center">
    <div className="merchant-modal-wrap">
      <div className="merchant-modal-head flex flex-Jbetween">
        <span>{title}</span>
        <Icon icon="times" className="merchant-modal-close flex-center" onClick={toggleModal} />
      </div>
      <div className="merchant-modal-form">
        <div className="merchant-form-item flex-col">
          <span className="form-item-title">姓名：</span>
          <input className="form-item-input" type="text" placeholder="请输入姓名" name="merchantName" onChange={handleInputChange} />
        </div>
        <div className="merchant-form-item flex-col">
          <span className="form-item-title">手机号：</span>
          <input className="form-item-input" type="text" placeholder="请输入手机号" name="merchantPhone" onChange={handleInputChange} />
        </div>
        <div className="merchant-form-item flex-col">
          <span className="form-item-title">品牌名称：</span>
          <input className="form-item-input" type="text" placeholder="请输入品牌名称" name="merchantBrand" onChange={handleInputChange} />
        </div>
      </div>
      <div className="merchant-modal-bottom">
        <div className="modal-btn-cancel flex-center" onClick={toggleModal}>取消</div>
        <div className="modal-btn-submit flex-center" onClick={onSubmit}>提交</div>
      </div>
    </div>
  </div>
  )
}